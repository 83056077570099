.profile-img {
  width: 240px;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  --color: initial;
  display: block;
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
