.header {
  left: 0;
  right: 0;
  top: 0%;
  padding: 10px;
}

.header strong {
  font-size: 20px;
  line-height: 26px;
}

.header p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.header a {
  text-decoration: none;
}
.title-default {
  text-align: center;
}
.signout {
  position: relative;
}
.profile {
  position: relative;
}
.logo {
  position: relative;
  width: 80px;
}

.header img {
  position: relative;
  float: left;
  width: 80px;
}

.header ion-card {
  padding: 0px;
}

.header ion-item {
  --border-color: #fafafa !important;
  padding: 0px;
}

.link {
  cursor: pointer;
  white-space: nowrap;
}
