.layout-card {
    height: 375px;
}

.draggable-component { 
    width: 250px;
    height: 100px;
}

.remove {
    line-height: 0.5px;
}

[role="button"] {
    cursor: pointer;
    top:0;
    right: 0;
    margin: 2px;
}

.card-borderless {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: transparent !important;
    border-radius: 0.25rem;
}

.card-body {
    padding: 0!important;
}