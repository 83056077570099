/* Applies a text wrap on ion options*/
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.avatar {
  vertical-align: middle;
  text-align: center;
}
ion-item {
  --border-color: white;
  --highlight-color-invalid: pink;
  --highlight-color-valid: yellow;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  --color: initial;
  display: block;
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
