.latest-reading ion-card {
  padding: 0px;
}

.latest-reading ion-item {
 
}

.latest-reading ion-header {
  text-align: center;
}

.latest-reading ion-text {
  font-size: 12px;
  text-align: center;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  --color: initial;
  display: block;
  
  font-family: var(--ion-font-family, inherit);
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.scroll-items {
  justify-content: flex-start; 
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.scroll-items ion-col { 
    display: block;
    flex-wrap: nowrap;
}



.breach{
  background-color: rgba(220, 0, 20, 0.2);
  --ion-background-color:rgba(220, 0, 20, 0.2) !important;
  color:red !important;
}
.breach .latestreading-card-content{
  color: red !important;
  background: rgba(220, 0, 20, 0.2) !important;
  --ion-background-color:transparent !important;
  border: transparent!important;
  box-shadow: none;
}
.breach .latestreading-card-content ion-item{
  --border-color: transparent!important;
  color:#990000;
}
