.inactive-user {
  font-size: 32px;
  color: red;
}

.active-user {
  font-size: 32px;
  color: green;
}

.active-label {
  color: green;
  font-size: 10px;
}

.inactive-label {
  color: red;
  font-size: 10px;
}
