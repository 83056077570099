.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 320px) {
  .flex-container.wrap>div {
    width: 50%;
    max-width: 50%;
    text-overflow: "";
    white-space: nowrap;
    overflow: hidden;
  }
}

.inactive-user {
  font-size: 32px;
  color: red;
}

.active-user {
  font-size: 32px;
  color: green;
}

.active-label {
  color: green;
  font-size: 10px;
}

.inactive-label {
  color: red;
  font-size: 10px;
}

.select-text {
  overflow: unset;
}