/* Applies a text wrap on ion options*/
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.avatar {
  vertical-align: middle;
  text-align: center;
}

ion-row ion-textarea,
ion-col ion-input,
ion-row ion-select,
.PhoneInputInput {
  border: solid 1px #a1a1a1;
  border-radius: 8px;
  padding: 4px;
}
