/* Applies a text wrap on ion options*/
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.avatar {
  vertical-align: middle;
  text-align: center;
}

.fill-vertical-space {
  display: -webkit-flex;
  display: flex;

  flex-direction: column;
  height: 100%;
}

.fill-vertical-space > div,
.fill-vertical-space > .row {
  -webkit-flex: 1;
  flex: 1;
}
.scroll {
  height: 100%;
}
.link {
  cursor: pointer;
}

.profile-img {
  width: 180px;
}

.col-3 , .col-6{
  padding-right: 0px !important;
    padding-left: 0px !important;
}


.ion-charts .ion-card{
      height: 480px;
      width: 100%;
  }
