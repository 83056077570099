/* Applies a text wrap on ion options*/
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.my-custom-class {
  --background: #222;
  --spinner-color: #fff;

  color: #fff;
}

.avatar-thumb {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.fill-vertical-space {
  display: -webkit-flex;
  display: flex;

  flex-direction: column;
  height: 100%;
}

.fill-vertical-space > div,
.fill-vertical-space > .row {
  -webkit-flex: 1;
  flex: 1;
}
.scroll {
  height: 100%;
}
.link {
  cursor: pointer;
}


.col-12 , .col-8{
  padding-right: 0px !important;
    padding-left: 0px !important;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}


.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: inherit !important;
    margin: 10px!important;
}

