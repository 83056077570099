.link {
  cursor: pointer;
}

.avatar-thumb {
  padding: 0px;
  width: 100% !important;
  height: 100% !important;
  max-width: 40px !important;
  max-height: 40px !important;
}

.inactive-user {
  font-size: 24px !important;
  /* color: red !important; */
  cursor: pointer;
}

.active-user {
  font-size: 24px !important;
  /* color: green !important; */
  cursor: pointer;
}

.active-label {
  /* color: green !important; */
  font-size: 12px !important;
  cursor: pointer;
}

.inactive-label {
  /* //color: red !important; */
  font-size: 12px !important;
  cursor: pointer;
}

.icons {
  cursor: pointer;
}

.col-header {
  background-color: var(--ion-color-primary, #3880ff) !important;
  color: white;
  text-align: left;
}

.col-data {
  text-align: left;
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
}