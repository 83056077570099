.container {
    width: 100%;
    overflow-y: auto;

    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
    display: none;
}